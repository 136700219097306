import { AuthProvider, ProtectRoute } from './AuthContext';
import { AppCompanyContext } from './CompanyContext';
import { NotificationProvider } from './NotificationContext';
import { AppUserMeContext } from './UserMeContext';

const AppContext = ({ children }: { children: React.ReactNode }) => (
  <AuthProvider>
    <ProtectRoute>
      <AppUserMeContext>
        <NotificationProvider>
          <AppCompanyContext>{children}</AppCompanyContext>
        </NotificationProvider>
      </AppUserMeContext>
    </ProtectRoute>
  </AuthProvider>
);
export default AppContext;

/* eslint-disable sonarjs/cognitive-complexity */
import { getUserMe } from '@api/GET_Me';
import { postUserLogin } from '@api/POST_Login';
import { LoadingIndicatorBox } from '@uangcermat/uikit-web';
import { appCookies } from '@utils/appCookies';
import { epochFormatter } from '@utils/epochTimeFormatter';
import { isAxiosError } from '@utils/errorUtils';
import { localStorageService } from '@utils/localStorage';
import { syncRefreshToken } from '@utils/shouldRefreshToken';
import {
  DataMeInterface,
  LanguageType,
  PostBodyLoginInterface,
  UserCompaniesInterface
} from 'interface/UserInterface';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { getUserLogout } from '../api/GET_Logout';

interface AuthContextInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingLogin: boolean;
  userAuth: DataMeInterface | null;
  errorMessage: string | null;
  errorValidationEmail: string | null;
  errorValidationPassword: string | null;
  setErrorValidationEmail: (message: null | string) => void;
  setErrorValidationPassword: (message: null | string) => void;
  setErrorMessage: (message: null | string) => void;
  login: (value: PostBodyLoginInterface) => void;
  logout: () => void;
  setUserAuth: (value: DataMeInterface) => void;
  setActiveCompany: (company: UserCompaniesInterface) => void;
  setIsResetCompany: (value: boolean) => void;
  isResetCompany: boolean;
  activeCompany: UserCompaniesInterface | null;
  companyGroup: Array<string>;
  refetchMe: () => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  isLoading: true,
  isLoadingLogin: true,
  userAuth: null,
  errorMessage: null,
  setErrorMessage: () => undefined,
  login: () => undefined,
  logout: () => undefined,
  setUserAuth: () => undefined,
  setActiveCompany: () => undefined,
  setIsResetCompany: () => undefined,
  activeCompany: null,
  isResetCompany: false,
  companyGroup: [],
  errorValidationEmail: null,
  errorValidationPassword: null,
  setErrorValidationEmail: () => undefined,
  setErrorValidationPassword: () => undefined,
  refetchMe: () => undefined
});

const isRedirectedToDashboard = (pathname: string): boolean => {
  return ['/', '/login'].includes(pathname);
};

const TYPE_LOGIN_REDIRECT: Array<string> = ['password', 'pin'];

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userAuth, setUserAuth] = useState<DataMeInterface | null>(null);
  const [activeCompany, setActiveCompany] = useState<UserCompaniesInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [errorValidationEmail, setErrorValidationEmail] = useState<null | string>(null);
  const [errorValidationPassword, setErrorValidationPassword] = useState<null | string>(null);
  const [isResetCompany, setIsResetCompany] = useState<boolean>(false);
  const [companyGroup, setCompanyGroup] = useState<Array<string>>([]);

  const { setCookie, removeCookie } = appCookies();

  const router = useRouter();
  const { tk: resetToken, type } = router.query;

  const capitalizeName = ({ name }: { name: string }) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchUserProfile = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async () => {
      if (!userAuth) {
        const { data: userData, error } = await getUserMe();
        const activeCompany =
          (localStorageService.getActiveCompany('active_company') as string) ?? '';
        if (!error && userData) {
          const { name } = userData;
          const newName = capitalizeName({ name: name });
          setUserAuth({
            ...userData,
            name: newName
          });
          if (userData.groups?.companies.length > 0) {
            const companyIds = userData.groups.companies.map(
              (company: UserCompaniesInterface) => company.id
            );
            setCompanyGroup(companyIds);
            const findIndexCompany = userData.groups.companies.findIndex(
              (company: UserCompaniesInterface) => company.id === activeCompany
            );
            if (findIndexCompany >= 0) {
              setActiveCompany(userData.groups.companies[findIndexCompany]);
            } else {
              setActiveCompany(userData.groups.companies[0]);
            }
          }
        }
        if (resetToken && type && TYPE_LOGIN_REDIRECT.includes(type as string)) {
          logout();
          router.push(`/login?tk=${resetToken}&type=${type}`);
        } else if (isRedirectedToDashboard(router.pathname)) {
          router.push(userData?.redirectAfterLogin ?? '/payroll/approval');
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router, userAuth]
  );

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('access_token');
      const refreshToken = Cookies.get('refresh_token');
      if (token) {
        if (!userAuth) {
          fetchUserProfile();
        }
      } else {
        if (refreshToken) {
          if (type !== 'autologin') {
            syncRefreshToken().finally(() => {
              fetchUserProfile();
            });
          }
        } else {
          setIsLoading(false);
          if (router.pathname !== '/login') {
            router.push('/login');
          }
        }
      }
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isResetCompany) {
      setIsResetCompany(false);
    }
  }, [isResetCompany]);

  const login = async ({
    email,
    password,
    lang
  }: {
    email: string;
    password: string;
    lang: LanguageType;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }) => {
    try {
      setIsLoadingLogin(true);
      const { access_token, error, expire_token, message, refresh_token } = await postUserLogin({
        email,
        password,
        lang
      });

      if (!error && access_token && !userAuth) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: { expires: expire_token ? epochFormatter(expire_token) : undefined }
        });

        if (refresh_token) {
          setCookie({
            name: 'refresh_token',
            value: refresh_token
          });
        }

        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token?.toString()
        });

        localStorageService.setNotification({
          key: 'notification',
          value: '1'
        });
        fetchUserProfile();
      }
      if (error && message) {
        if (typeof message === 'string') {
          setErrorMessage(message);
          setIsLoadingLogin(false);
        } else {
          if (message.email) setErrorValidationEmail(message.email[0]);
          if (message.password) setErrorValidationPassword(message.password[0]);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        const errMsg = error.response?.data.message;
        setErrorMessage(errMsg?.email || errMsg?.password || error.message);
      }
    } finally {
      setIsLoadingLogin(false);
    }
  };

  const logout = async () => {
    try {
      const res = await getUserLogout();
      if (res && !res.error) {
        removeCookie({
          name: 'access_token'
        });
        removeCookie({
          name: 'refresh_token'
        });
        setUserAuth(null);
        setActiveCompany(null);
        router.push('/login');
        localStorageService.setNotification({
          key: 'notification',
          value: '0'
        });
      } else {
        alert(res.message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (isAxiosError(error)) {
        alert(error?.message);
      }
    }
  };

  const handleRefetchMe = () => {
    const token = Cookies.get('access_token');
    if (token) {
      setIsLoading(true);
      fetchUserProfile();
    }

    return setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!userAuth,
        userAuth,
        login,
        isLoading,
        isLoadingLogin,
        logout,
        errorMessage,
        setUserAuth,
        activeCompany,
        setActiveCompany,
        setErrorMessage,
        setIsResetCompany,
        isResetCompany,
        companyGroup,
        errorValidationEmail,
        errorValidationPassword,
        setErrorValidationEmail,
        setErrorValidationPassword,
        refetchMe: handleRefetchMe
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

// eslint-disable-next-line react/prop-types
export const ProtectRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const router = useRouter();

  if (isLoading || (!isAuthenticated && router.pathname !== '/login')) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <LoadingIndicatorBox backdrop />
      </div>
    );
  }
  return <>{children}</>;
};

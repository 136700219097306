import '@uangcermat/uikit-web/build/index.css';
import 'tailwindcss/tailwind.css';

import AppContext from '@context/AppContext';
import { useI18n } from '@hooks/useI18n';
import DashboardLayout from '@layout/DashboardLayout';
import { ColorBlue, ColorDark, ColorLight } from '@uangcermat/uikit-web';
import { AppTrackingInterface } from 'interface/TrackingInterface';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { track } from 'react-tracking';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: ${ColorLight.whiteSmoke};
    height: 100%;
    margin:0;
    padding: 0;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  #__next {
    height: 100%;
  }
  .wrapper {
    min-height: 100vh;
    max-width: 500px;
    margin: auto;
    background-color: ${ColorLight.whiteSmoke};
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    position: absolute;
    background: #FFFFFF;
    box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.25);
  }
  
  ::-webkit-scrollbar-thumb {
    position: absolute;
    background: #52616B; 
    border-radius: 23px;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    background-color: ${ColorLight.whiteSolid} !important;
    border-color: ${ColorBlue.lapisLazuli} !important;
  }

  .rs-calendar-table-cell-is-today > .rs-calendar-table-cell-content:hover  {
    color: ${ColorDark.blackCoral} !important;
  }
  .rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content,
  .rs-picker-toolbar-right-btn-ok,
  .rs-calendar-time-dropdown-cell-active,
  .rs-calendar-table-cell-selected > .rs-calendar-table-cell-content
  {
    background-color: ${ColorBlue.buttonBlue} !important;
  }

  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: ${ColorDark.blackCoral} !important;
  }


  //TODO move to ui kit 
  div[data-test-id="avatarComponent"] > svg {
    align-self: center;
    display: flex;
    padding-left: 4px;
    padding-top: 4px;
  }
  
  //FIXME: add props maxHeight on selectPicker
  .rs-picker-menu > div:nth-child(2) > .rs-picker-select-menu-items {
    max-height: 240px !important;
  }
  //FIXME: add theming color for language-switcher
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    color: ${ColorDark.gumbo} !important;
    background-color: ${ColorLight.whiteSmoke} !important;
  }
  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover {
    color: ${ColorDark.gumbo} !important;
    background-color: ${ColorLight.whiteSmoke} !important;
  }
  [data-test-id='navbarNotification'] {
    ul {
      top: 2px !important;
    }
  }
`;

const URL_MAPPER = ['/', 'payroll', 'approval', 'profile'];
// FIXME: add props types of MyApp, got an issue from nextjs about parse `err` props
// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps, err }: AppProps & { err: unknown }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 2,
            staleTime: 50
          }
        }
      })
  );

  const router = useRouter();

  type RouteMapperType = {
    [key: string]: string;
  };

  const { init } = useI18n();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routerHeadMapper: RouteMapperType = {
    '/dashboard/loan/[loanId]': 'Loan Detail'
  };

  const headTitle = `${
    routerHeadMapper[router.pathname] ? routerHeadMapper[router.pathname] + ' - ' : ''
  }${process.env.APP_NAME} Admin`;

  const ComponentWrapper = () => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      <Component {...pageProps} err={err} />
    );
  };

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />

        <link rel="preload" href="/fonts/poppins/Poppins-ExtraBold.ttf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/poppins/Poppins-Bold.ttf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/poppins/Poppins-SemiBold.ttf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/poppins/Poppins-Medium.ttf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/poppins/Poppins-Light.ttf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/poppins/Poppins-Thin.ttf" as="font" crossOrigin="" />
        <link href="/font.css" rel="stylesheet" />

        <title>{headTitle}</title>
        <meta name="description" content={headTitle} />
        <meta name="keywords" content={headTitle} />

        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/icons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />

        <link rel="manifest" href="/manifest.json" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <GlobalStyle />
      <div id="root" className="h-full">
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          <Hydrate state={pageProps.dehydratedState}>
            <AppContext>
              {URL_MAPPER.includes(router.pathname.split('/')[1]) ? (
                <DashboardLayout>
                  <ComponentWrapper />
                </DashboardLayout>
              ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <Component {...pageProps} err={err} />
              )}
            </AppContext>
          </Hydrate>
        </QueryClientProvider>
      </div>
    </>
  );
};

const TrackedApp = track(
  {},
  {
    dispatch: (data: AppTrackingInterface) => {
      const trackProperties = {};

      if (data.properties) {
        Object.assign(trackProperties, data.properties);
      }

      // TODO: send tracker data to server
    }
  }
)(MyApp);

export default TrackedApp;

import {
  EnglishCommon,
  EnglishLoginPage,
  EnglishMenu,
  EnglishPayroll,
  EnglishProfile,
  EnglishUserDetail
} from '@i18n/locales/en';
import {
  BahasaCommon,
  BahasaLoginPage,
  BahasaMenu,
  BahasaPayroll,
  BahasaProfile,
  BahasaUserDetail
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
import { localStorageService } from '@utils/localStorage';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    profile: EnglishProfile,
    userDetail: EnglishUserDetail,
    payroll: EnglishPayroll
  };

  const bahasaResources = {
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    profile: BahasaProfile,
    userDetail: BahasaUserDetail,
    payroll: BahasaPayroll
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: ['login', 'menu', 'common', 'profile', 'userDetail', 'payroll']
  };

  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};

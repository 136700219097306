import { AxiosError, AxiosResponse } from 'axios';
import { PostBodyLoginInterface, ResponseLoginInterface } from 'interface/UserInterface';

import { axiosAuthInstance } from './axios.authConfig';

export interface ValidationLoginInterface {
  email?: string[];
  password?: string[];
}
export interface CustomResponseLoginInterface extends Omit<ResponseLoginInterface, 'message'> {
  message?: string | ValidationLoginInterface | null;
}

export const postUserLogin = async (
  body: PostBodyLoginInterface
): Promise<CustomResponseLoginInterface> => {
  return axiosAuthInstance
    .post('/login', body)
    .then((res: AxiosResponse<CustomResponseLoginInterface>) => res.data)
    .catch((error: AxiosError) => error?.response?.data);
};

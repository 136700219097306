// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('./package');

const APP_VERSION_RELEASE =
  process.env.APP_ENV === '_PROD_'
    ? `${packageJson.version}`
    : `${process.env.APP_ENV}:${packageJson.version}`;

if (process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: APP_VERSION_RELEASE,
    environment: process.env.APP_ENV
  });
}

import { BaseResponseInterface } from '@interface/BaseResponseInterface';
import { ParamsBulkNotifIdInterface } from '@interface/NotificationInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { axiosAuthInstance } from './axios.authConfig';

export const postNotificationUndoDelete = async (
  params: ParamsBulkNotifIdInterface
): Promise<BaseResponseInterface> => {
  const { data } = await axiosAuthInstance.post('/notifications/multipleUndoDelete', params);
  return data;
};

export function usePostNotificationUndoDelete(
  mutateFn?: UseMutationOptions<
    BaseResponseInterface,
    AxiosError<BaseResponseInterface>,
    ParamsBulkNotifIdInterface
  >
) {
  return useMutation<
    BaseResponseInterface,
    AxiosError<BaseResponseInterface>,
    ParamsBulkNotifIdInterface
  >(postNotificationUndoDelete, mutateFn);
}

import { NavigationMenuInterface } from '@uangcermat/uikit-web/build/src/blocks/Sidebar/Sidebar.type';

interface CustomChildrenInterface extends NavigationMenuInterface {
  permission: string;
}

interface CustomNavigationInterface extends NavigationMenuInterface {
  children: Array<CustomChildrenInterface>;
}

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<CustomNavigationInterface>;
}) => {
  const finalNav: Array<CustomNavigationInterface> = [];
  const userPermissionTmp: Record<string, Array<string>> = {};

  userPermission.map((nav) => {
    const menu = nav.split('.');
    if (menu[0] === 'payroll' && userPermissionTmp[menu[1]] === undefined) {
      userPermissionTmp[menu[1]] = [`${menu[1]}.${menu[2]}`];
    } else {
      if (userPermissionTmp[menu[1]]) {
        userPermissionTmp[menu[1]] = [...userPermissionTmp[menu[1]], `${menu[1]}.${menu[2]}`];
      }
    }
  });
  navConfig.map((menu) => {
    const sub: Array<CustomChildrenInterface> = [];
    if (menu?.children) {
      menu.children.map((submenu) => {
        const submenuPermissionSplit = submenu.permission.split('.');
        if (userPermissionTmp[submenuPermissionSplit[0]]?.includes(submenu.permission)) {
          sub.push(submenu);
        }
      });
    }
    if (sub.length) finalNav.push({ ...menu, children: sub });
  });
  return finalNav;
};

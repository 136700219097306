import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { useAuth } from '@context/AuthContext';
import { CompanyContext } from '@context/CompanyContext';
import { useNotification } from '@context/NotificationContext';
import useGetMeApplication from '@hooks/useGetMeAplication';
import useGetMeRefreshToken from '@hooks/useGetMeRefreshToken';
import { useI18n } from '@hooks/useI18n';
import { useNavigationConfig } from '@hooks/useNavigationConfig';
import { NotificationItemInterface, TabsType } from '@interface/NotificationInterface';
import { DataMeInterface, LanguageType } from '@interface/UserInterface';
import {
  ColorBlue,
  ColorDark,
  ColorLight,
  ColorRed,
  Image,
  Navbar,
  NavbarCompanyDropdown,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { NotificationGroupInterface } from '@uangcermat/uikit-web/build/src/blocks/Navbar/Navbar.type';
import { dateFormatter } from '@utils/dateFormatter';
import { getGuardedNavigation } from '@utils/getGuardedNavigation';
import { getInitialNameForFallbackAvatar } from '@utils/getInitialName';
import { localStorageService } from '@utils/localStorage';
import { syncRefreshToken } from '@utils/shouldRefreshToken';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths
} from 'date-fns';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import pkgJson from '../../package.json';

// FIXME: styled should be fixed at uiKit
const NavbarContainerStyled = styled.div`
  ul {
    z-index: 9;
    top: 4rem !important;
  }
  img {
    object-fit: scale-down;
  }
  //FIXME: styled should be fixed at uiKit for rounded style
  img[data-test-id='avatarComponent'] {
    border-radius: 4px !important;
    object-fit: fill;
  }
  //FIXME: styled should be fixed at uiKit when apps only set to one company
  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 16px;
      /* button {
        &:hover {
          background-color: ${ColorDark.blackCoral};
          border: ${ColorDark.blackCoral};
          div > p {
            color: #ffffff !important;
          }
        }
      } */
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const companyImgUrl = '/images/navbar-logo.svg';
const logoCompanyGC = '/images/logo-gc.svg';

const appData = {
  appName: process.env.APP_NAME,
  appVersion: pkgJson.version
};

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  min-width: 208px !important;
`;

const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

const initialUserData: NavbarUserDropdown = {
  name: '',
  email: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined,
  onClickEditProfile: () => undefined
};

const initialCompanyDropdown: NavbarCompanyDropdown = {
  onClickBranch: () => undefined,
  companyName: '',
  companyBranchRefElm: null,
  logoUrl: '',
  listCompany: [],
  isShowBranch: false,
  activeColor: ColorRed.deepCarmine,
  hoverColor: '',
  hoverItemColor: ColorLight.whiteSmoke,
  onClickBranchSwitcher: () => undefined
};

const TODAY = new Date();

export default function DashboardLayout({ children }: { children: React.ReactNode }) {
  const { t, i18n } = useTranslation(['common']);
  const { logout, userAuth, setUserAuth, activeCompany, isResetCompany, setIsResetCompany } =
    useAuth();

  const [notifList, setNotifList] = useState<Array<NotificationGroupInterface>>([]);
  const {
    activeTab,
    notifData,
    isLoading,
    isShowNotification,
    totalUnread,
    isViewMore,
    isEmpty,
    isModify,
    currentPage,
    isSelectAll,
    selectedItem,
    isDelete,
    isShowUndoDelete,
    isShowPopupNotification,
    setIsShowPopupNotification,
    onDeleteConfirmation,
    onBulkMarkAsRead,
    onUndoDelete,
    setIsDelete,
    setIsSelectAll,
    setCurrentPage,
    setIsModify,
    setIsShowNotification,
    fetchCounter,
    fetchList,
    fetchMoreData,
    fetchSelectedItem,
    setActiveTab,
    clickDetailItem,
    resetSelectedItem
  } = useNotification();
  const notificationRefElm = useRef(null);

  const [activeId, setActiveId] = useState('');
  const [companyData, setCompanyData] = useState<NavbarCompanyDropdown>(initialCompanyDropdown);
  const { changeLanguage } = useI18n();
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const companyBranchSwitcherRef = useRef(null);

  useClickOutside({
    ref: wrapperRef,
    cb: () => {
      setIsOpen(false);
    }
  });

  useClickOutside({
    ref: notificationRefElm,
    cb: () => {
      setIsShowPopupNotification(false);
      setIsModify(false);
      setActiveTab('all');
      setCurrentPage(1);
    }
  });

  const currentActiveCompany = localStorageService.getActiveCompany('active_company') ?? '';

  const handleChangeLanguage = async (to: LanguageType) => {
    await postChangeLanguage({ lang: to });
    await syncRefreshToken();
    changeLanguage(to);
    window.location.reload();
  };
  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });
  const { setCompany } = useContext(CompanyContext);

  useEffect(() => {
    setIsShowNotification(false);
    changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notificationStorage = localStorageService.getNotification('notification');
    if (notificationStorage !== '0' && activeCompany && userAuth && !isShowNotification) {
      setTimeout(() => {
        fetchCounter();
        setIsShowNotification(true);
      }, 100);
    }
  }, [activeCompany, fetchCounter, isShowNotification, setIsShowNotification, userAuth]);

  const { navigationConfig } = useNavigationConfig();

  const router = useRouter();

  useEffect(() => {
    const companyId = userAuth?.groups.companies.find(
      (company) => company.id === currentActiveCompany
    )?.id;

    if (companyId) {
      setCompany({
        id: companyId
      });
    }
    return () => {
      setCompany(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, currentActiveCompany, setCompany]);

  // const routeActivityReport = '/';

  const getDiffDate = useCallback(
    ({ date }: { date: Date }) => {
      let diffTextString = '';
      const diffMonths = differenceInMonths(TODAY, date);
      const diffDays = differenceInDays(TODAY, date);
      const diffHours = differenceInHours(TODAY, date);
      const diffMinutes = differenceInMinutes(TODAY, date);
      if (diffMonths <= 1) {
        if (!diffDays && !diffHours && diffMinutes <= 1) {
          diffTextString = t('common:notification.minuteAgoLabel');
        } else if (!diffDays && !diffHours && diffMinutes > 1) {
          diffTextString = `${diffMinutes} ${t('common:notification.minutesAgoLabel')}`;
        } else if (!diffDays && diffHours === 1) {
          diffTextString = t('common:notification.hourAgoLabel');
        } else if (!diffDays && diffHours > 1 && diffHours < 24) {
          diffTextString = `${diffHours} ${t('common:notification.hoursAgoLabel')}`;
        } else if (diffDays > 7) {
          diffTextString = dateFormatter(date, 'dd MMMM yyyy', {
            locale: i18n.language as LanguageType
          });
        } else if (diffDays === 1) {
          diffTextString = t('common:notification.dayAgoLabel');
        } else {
          diffTextString = `${diffDays} ${t('common:notification.daysAgoLabel')}`;
        }
      } else {
        diffTextString = `${diffMonths} ${t('common:notification.monthAgoLabel')}`;
      }
      return diffTextString;
    },
    [i18n.language, t]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!isLoading && isShowNotification && notifData && notifData.length) {
      const newList: Array<NotificationGroupInterface> = [];
      notifData.map((notif: NotificationItemInterface, index: number) => {
        const groupName = notif.group === '7_days' ? t('common:current') : t('common:older');
        if (index === 0) {
          newList.push({
            index: index + 1,
            group: notif.group,
            title: groupName as string,
            isModify,
            selectedItem,
            list: [
              {
                id: notif.id,
                title: notif.title,
                isModify,
                isNewData: notif.status === 'SENT',
                isSelected: selectedItem.includes(notif.id),
                description: notif.content,
                time: getDiffDate({ date: new Date(notif.timestamp) }),
                url: notif.navigate ?? '',
                avatarUrl: notif.avatar ?? '',
                avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
                onClickItem: () => clickDetailItem(notif.id, notif.action),
                selectedItem
              }
            ]
          });
        } else {
          const groupIndex = newList.findIndex(
            (datas: NotificationGroupInterface) => datas.group === notif.group
          );
          if (groupIndex >= 0) {
            const currentList = newList[groupIndex].list;
            currentList.push({
              id: notif.id,
              title: notif.title,
              isModify,
              isNewData: notif.status === 'SENT',
              isSelected: selectedItem.includes(notif.id),
              description: notif.content,
              time: getDiffDate({ date: new Date(notif.timestamp) }),
              url: notif.navigate ?? '',
              avatarUrl: notif.avatar ?? '',
              avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
              onClickItem: () => clickDetailItem(notif.id, notif.action),
              selectedItem
            });
          } else {
            newList.push({
              index: index + 1,
              group: notif.group,
              title: groupName as string,
              isModify,
              selectedItem: [],
              list: [
                {
                  id: notif.id,
                  title: notif.title,
                  isModify,
                  isNewData: notif.status === 'SENT',
                  isSelected: selectedItem.includes(notif.id),
                  description: notif.content,
                  time: getDiffDate({ date: new Date(notif.timestamp) }),
                  url: notif.navigate ?? '',
                  avatarUrl: notif.avatar ?? '',
                  avatarText: getInitialNameForFallbackAvatar({ name: notif.title }),
                  onClickItem: () => clickDetailItem(notif.id, notif.action),
                  selectedItem
                }
              ]
            });
          }
        }
      });
      setNotifList(newList);
    }
  }, [
    notifData,
    isLoading,
    isShowNotification,
    isModify,
    selectedItem,
    clickDetailItem,
    t,
    getDiffDate
  ]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const setActiveCompanyByAuth = async () => {
      if (activeCompany && userAuth) {
        const { id } = activeCompany;
        setCompanyData({
          ...companyData,
          companyName: userAuth.groups.name,
          logoUrl: userAuth.groups.logo,
          isClickSwitcherNoBranchEnabled: false,
          companyBranchRefElm: companyBranchSwitcherRef,
          switchCompanyLabel: undefined,
          onClickSwitchCompany: undefined,
          hoverColor: '',
          hoverItemColor: '',
          activeColor: '',
          listCompany: [],
          isHoverable: false
        });
        localStorageService.setActiveCompany('active_company', id ?? '');
      }
    };
    if (!companyData.companyName && !companyData.logoUrl) {
      setActiveCompanyByAuth();
    } else if (isResetCompany && activeCompany && activeCompany.id) {
      setCompanyData({
        ...companyData,
        companyName: userAuth?.groups.name ?? '',
        logoUrl: userAuth?.groups.logo ?? '',
        listCompany: []
      });
      localStorageService.setActiveCompany('active_company', activeCompany.id ?? '');
      setIsResetCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, activeCompany, isResetCompany]);

  const setNavbarData = useCallback(
    (data: DataMeInterface) => {
      setUserData({
        ...userData,
        hoverColor: ColorRed.deepCarmine,
        hoverItemColor: ColorLight.whiteSmoke,
        email: data.email || '',
        name: data.name || '',
        avatarUrl: data?.profilePicture || '',
        onClickLogout: logout
      });

      // changeActiveCompany(data.companies[0]?.name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout, setUserAuth, userAuth?.name, userData.isShowAccount]
  );

  useEffect(() => {
    if (userAuth) {
      setNavbarData(userAuth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  type SidebarMapType = {
    [key: string]: string;
  };

  useEffect(() => {
    const sidebarActiveIdMapper: SidebarMapType = {
      '/payroll/approval': 'approval',
      '/payroll/disbursal': 'disbursal',
      '/payroll/history': 'history',
      '/payroll/prefund': 'prefund'
    };

    setActiveId(sidebarActiveIdMapper[router.route]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveId]);

  const { data: getMeApplication } = useGetMeApplication({
    options: {
      enabled: true
    }
  });

  const { refetch: refetchgetMeRefreshToken } = useGetMeRefreshToken({
    options: {
      enabled: false,
      onSuccess: (response) => {
        location.href = `${process.env.GAJICERMAT_URL}/login?tk=${response.data.refreshToken}&type=autologin`;
      }
    }
  });

  const footerButtonLogo = (values: string | undefined) => {
    if (values) {
      return <Image src={logoCompanyGC} alt="Logo-gc" height={40} width={154} />;
    }

    return undefined;
  };

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          <Navbar
            appLogoUrl={companyImgUrl}
            onClickLogo={() => router.push('/payroll/approval')}
            company={{
              ...companyData,
              onClickBranchSwitcher: () => undefined,
              onClickBranch: () => undefined
            }}
            isHideCompany={false}
            user={{
              ...userData,
              userRefElm: wrapperRef,
              isShowAccount: isOpen,
              onClickProfile: () => {
                setIsOpen((prev) => !prev);
              },
              onClickEditProfile: () => {
                router.push('/profile');
                setIsOpen((prev) => !prev);
              }
            }}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: ColorBlue.cornFlowerBlue,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            bgColor={ColorBlue.darkBlue}
            isShowNotification={isShowNotification}
            notifications={{
              isLoading,
              isModify,
              isShowNotification: isShowPopupNotification,
              notificationRefElm: notificationRefElm,
              data: notifList,
              isEmpty,
              isShowUndoDelete,
              isShowMore: isViewMore,
              totalData: 0,
              totalPage: 0,
              totalUnread,
              locale: currentLanguage,
              tabActive: activeTab,
              selectedItem,
              isSelectAll,
              isDelete,
              isShowDeleteConfirmation: isDelete,
              onClickCancel: () => {
                setIsModify((prev) => !prev);
                setIsDelete(false);
                resetSelectedItem();
              },
              onClickCancelDelete: () => setIsDelete((prev) => !prev),
              onClickDeleteConfirmation: () => onDeleteConfirmation(),
              onClickDelete: () => setIsDelete((prev) => !prev),
              onClickMarkAsRead: () => onBulkMarkAsRead(),
              onClickModify: () => setIsModify((prev) => !prev),
              onClickRollbackDelete: () => onUndoDelete(),
              onClickSelectAll: () => {
                setIsSelectAll((prev) => !prev);
                fetchSelectedItem();
              },
              onClickTab: (value) => {
                setCurrentPage(1);
                setActiveTab(value as TabsType);
                resetSelectedItem();
              },
              onClickUnread: () => undefined,
              onClickViewMore: () => {
                setCurrentPage(currentPage + 1);
                setTimeout(() => {
                  fetchMoreData();
                }, 100);
              },
              onClickViewNotification: () => {
                fetchList();
                setIsShowPopupNotification((prev) => !prev);
              }
            }}
          />
        </NavbarContainerStyled>
        <MainStyled>
          <SidebarContainerStyled>
            <Sidebar
              navigationConfig={getGuardedNavigation({
                navConfig: navigationConfig,
                userPermission: userAuth?.permissions ?? []
              })}
              onClickNav={(nav) => {
                setActiveId(nav.id);
                if (nav.navLink) {
                  router.push(nav.navLink);
                }
              }}
              activeId={activeId}
              appName={appData.appName}
              appVersion={appData.appVersion}
              footerBtnLogo={footerButtonLogo(getMeApplication?.data.gajicermat_web)}
              footerBtnOnClick={() => refetchgetMeRefreshToken()}
              activeParent="payroll"
            />
          </SidebarContainerStyled>
          <ContentContainerStyled>{children}</ContentContainerStyled>
        </MainStyled>
      </LayoutStyled>
    </>
  );
}

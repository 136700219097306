import { GetRefreshToken } from '@api/GET_RefreshToken';
import { compareDesc } from 'date-fns';
import { ResponseLoginInterface } from 'interface/UserInterface';
import Cookies from 'js-cookie';

import { appCookies } from './appCookies';
import { epochFormatter } from './epochTimeFormatter';
import { localStorageService } from './localStorage';

export const shouldRefreshToken = () => {
  const localExpireToken = localStorageService.getToken('expire_token');

  if (localExpireToken) {
    const expire_token = epochFormatter(parseInt(localExpireToken, 10));

    return compareDesc(new Date(), expire_token) === -1;
  }
  return false;
};

export const removeToken = () => {
  const { removeCookie } = appCookies();
  removeCookie({ name: 'access_token' });
  removeCookie({ name: 'refresh_token' });
  localStorageService.clearToken('expire_token');
  window.location.pathname = '/';
};

export const syncRefreshToken = async (refreshToken?: string): Promise<ResponseLoginInterface> => {
  const refresh_token = refreshToken ?? Cookies.get('refresh_token');
  const { setCookie } = appCookies();

  return await GetRefreshToken({
    token: refresh_token
  }).then(({ access_token, expire_token, refresh_token, error }) => {
    if (!error) {
      if (access_token && expire_token && refresh_token) {
        setCookie({
          name: 'access_token',
          value: access_token,
          options: { expires: epochFormatter(expire_token) }
        });

        setCookie({
          name: 'refresh_token',
          value: refresh_token
        });
        localStorageService.setToken({
          key: 'expire_token',
          value: expire_token.toString()
        });
      }
      return {
        access_token,
        expire_token,
        refresh_token,
        error: false
      };
    } else {
      removeToken();
      return {
        refresh_token: null,
        access_token: 'testing',
        expire_token: null,
        error: true
      };
    }
  });
};

import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

interface CustomChildrenInterface extends NavigationMenuInterface {
  permission: string;
}

interface CustomNavigationInterface extends NavigationMenuInterface {
  children: Array<CustomChildrenInterface>;
}

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<CustomNavigationInterface> = [
    {
      id: 'payroll',
      icon: 'payroll',
      iconSize: 'micro',
      title: t('menu:payroll'),
      children: [
        {
          id: 'approval',
          title: t('menu:approval'),
          permission: 'payrollCycle.list',
          navLink: '/payroll/approval'
        },
        {
          id: 'disbursal',
          title: t('menu:disbursal'),
          permission: 'payrollCycle.list',
          navLink: '/payroll/disbursal'
        },
        {
          id: 'history',
          title: t('menu:history'),
          permission: 'payrollCycle.list',
          navLink: '/payroll/history'
        },
        {
          id: 'prefund',
          title: t('menu:prefund'),
          permission: 'prefundBalance.list',
          navLink: '/payroll/prefund'
        }
      ]
    }
  ];

  return { navigationConfig };
};

import { axiosAuthInstance } from '@api/axios.authConfig';
import { ResponseMeApplicationInterface } from '@interface/UserInterface';
import { useQuery, UseQueryOptions } from 'react-query';

export const GetMeApplication = async (): Promise<ResponseMeApplicationInterface> => {
  const { data } = await axiosAuthInstance.get('me/applications');
  return data;
};

const useGetMeApplication = ({
  options
}: {
  options?: UseQueryOptions<ResponseMeApplicationInterface>;
}) => {
  return useQuery<ResponseMeApplicationInterface>(
    ['useGetMeApplication/application'],
    () => GetMeApplication(),
    options
  );
};

export default useGetMeApplication;

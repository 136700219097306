import * as dateFns from 'date-fns';
import en from 'date-fns/locale/en-US/index';
import id from 'date-fns/locale/id';

type DateFormatType =
  | 'dd MMMM yyyy'
  | 'yyyy-MM-dd'
  | 'dd-MMMM-yyyy'
  | 'dd MMM yyyy'
  | 'EEEE, dd MMMM yyyy'
  | 'EEE, dd MMM yyyy'
  | 'HH:mm:ss'
  | 'HH:mm'
  | 'yyyy-MM-dd'
  | 'yyyyMMdd'
  | 'dd MMMM yyyy HH:mm'
  | 'dd MMM yyyy HH:mm'
  | 'dd MMM yyyy - HH:mm'
  | 'MMMM yyyy'
  | 'dd LLL'
  | 'HH:mm - dd MMM yyyy'
  | 'LLLL yyyy'
  | 'EEE'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'EEEE, dd LLL'
  | 'yyyy-MM'
  | 'EEE, dd';

type DateFormatOption = Omit<Parameters<typeof dateFns.format>[2], 'locale'> & {
  locale?: 'id' | 'en';
};

export const dateFormatter = (
  date: Date,
  dateFormat?: DateFormatType,
  { locale = 'id', ...option }: DateFormatOption = {}
) => {
  return dateFns.format(date, dateFormat || 'dd MMMM yyyy', {
    locale: locale === 'en' ? en : id,
    ...option
  });
};

export const formatDistanceToNow = (date: Date) =>
  dateFns.formatDistanceToNow(date, {
    locale: id
  });

import { axiosAuthInstance } from '@api/axios.authConfig';
import { ResponseMeRefreshTokenInterface } from '@interface/UserInterface';
import { useQuery, UseQueryOptions } from 'react-query';

export const GetMeRefreshToken = async (): Promise<ResponseMeRefreshTokenInterface> => {
  const { data } = await axiosAuthInstance.get('me/refreshToken', {
    params: {
      platform: 'gajicermat-web'
    }
  });
  return data;
};

const useGetMeRefreshToken = ({
  options
}: {
  options?: UseQueryOptions<ResponseMeRefreshTokenInterface>;
}) => {
  return useQuery<ResponseMeRefreshTokenInterface>(
    ['useGetMeRefreshToken'],
    () => GetMeRefreshToken(),
    options
  );
};

export default useGetMeRefreshToken;
